const arrayRegex = /^\[(.*)\]$/;

const parseQueryString = (str = "") => {
  try {
    return JSON.parse(
      `{"${str
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')}"}`
    );
  } catch (error) {
    return {};
  }
};

const valueToArray = value => {
  try {
    return value.match(arrayRegex)[1].split(",");
  } catch (error) {
    return [];
  }
};

const parseValue = value => {
  const isString = typeof value === "string";
  if (isString && value.match(/^true$|^false$/gi)) {
    return JSON.parse(value.toLowerCase());
  }
  if (isString && value.match(arrayRegex)) {
    return valueToArray(value);
  }
  return value;
};

export const urlToObject = str => {
  const queryString = decodeURIComponent(str).split("?");
  const queryObject =
    queryString && queryString[1] ? parseQueryString(queryString[1]) : {};

  return Object.entries(queryObject).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: parseValue(value)
    }),
    {}
  );
};
